import { debounce, get, isObject } from "lodash";
import { mapGetters } from "vuex";
import multiselect from "@alias-npm/vue-multiselect";
import alertBoxV3 from "../alert-box-v3/alert-box-v3.vue";
import http from "@/services/api/http";
import { loadScript } from "@/helpers/utils";
import config from "@/config";

const DEBOUNCE_TIME = 500;
const ITEMS_LIMIT = 5;
const MIN_QUERY_LENGTH = 3;

export default {
  components: {
    alertBoxV3,
    multiselect,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    label: String,
    theme: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "np. Paryż",
    },
    startWithFallbackMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
      isLoading: false,
      selectedLocation: "",
      locations: [],
      lastQuery: "",
      limit: ITEMS_LIMIT,
      autoCompleteService: null,
      isFallbackMode: this.startWithFallbackMode,
    };
  },
  computed: {
    ...mapGetters("app", ["isLayoutDesktop"]),
    showNoResults() {
      return (
        !this.isLoading &&
        (this.lastQuery || "").length >= MIN_QUERY_LENGTH &&
        !this.startWithFallbackMode
      );
    },
    classModifiers() {
      return {
        "location-search-v3": true,
        "location-search-v3__multiselect": true,
        "location-search-v3--has-label": !!this.label,
        "location-search-v3--disabled": this.disabled,
        [`location-search-v3--${this.theme}`]: this.theme,
        "location-search-v3--is-empty": !this.selectedLocation,
        "location-search-v3--is-filled": !!this.selectedLocation,
      };
    },
  },
  methods: {
    clear() {
      this.selectedLocation = "";
      this.isFallbackMode = false;
    },
    onOpen() {
      const input = this.$refs.multiselect.$el.querySelector(
        ".multiselect__input"
      );
      if (!input) {
        return;
      }

      this.$nextTick(() => {
        input.value = this.lastQuery;
      });
    },
    fetch(query) {
      const src = "/searching/autocomplete/destination";

      const params = {
        q: query,
        ...this.data,
      };

      const request = http.get(src, { params });
      return Promise.race([request]);
    },
    fallbackSearch() {
      if (this.autoCompleteService === null) return;

      this.autoCompleteService.getQueryPredictions(
        {
          input: this.lastQuery,
        },
        (results) => {
          this.isFallbackMode = true;
          if (!results) {
            return;
          }
          this.locations = results.map((item) => {
            return {
              title: item.description,
              type: "google",
            };
          });
        }
      );
    },
    selectedLocationHandler(value) {
      const v = isObject(value) ? get(value, "title", "") : value || "";
      this.$emit("input", v);
    },
  },
  created() {
    this.asyncFind = debounce(
      (query) => {
        if (!query || query.length < MIN_QUERY_LENGTH) return;
        this.lastQuery = query;
        this.isFallbackMode = this.startWithFallbackMode;

        if (this.startWithFallbackMode) {
          this.fallbackSearch();
        } else {
          this.isLoading = true;
          this.fetch(query).then((response) => {
            this.locations = response.data.items;
            this.isLoading = false;
          });
        }
      },
      DEBOUNCE_TIME,
      { trailing: true }
    );
  },
  mounted() {
    if (this.value) {
      this.selectedLocation = {
        title: this.value,
      };
    }
    loadScript(config.googleMapsUrl, "google").then((google) => {
      this.autoCompleteService = new google.maps.places.AutocompleteService();
    });
  },
  watch: {
    selectedLocation: {
      handler: "selectedLocationHandler",
    },
  },
};
